import React from "react";
import { Bigsection } from "../satisfiedCustomers/style";
import { NavLink, Outlet } from "react-router-dom";

const Categories = () => {
  return (
    <Bigsection>
      <nav>
        <ul>
          {/* <NavLink to="show">
            <li>All Categories</li>
          </NavLink> */}
        </ul>
      </nav>
      <div>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default Categories;
