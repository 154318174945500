import React, { useEffect } from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import { useGetCompanyInfoQuery } from "../../../../../store/RTK/faq";
import CreateCategoryFaq from "../create";
import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Grid } from "@mui/material";
import { useLazyGetAllCategoriesQuery } from "../../../../../store/RTK/category";

export interface CompanyInfo {
  id: number;
  name: string;
  slogan: string;
  address: string;
  phone: string;
  mobile: string;
  logo: string;
  establishment: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  email: string;
  title: string;
  button_key: string;
  button_value: string;
  socials: any[]; // Assuming an array of unknown objects, replace `any` with specific type if known
  sliders: any[];
  intros: any[];
  services: any[];
  facts: any[];
  values: any[];
  whies: any[];
  projects: any[];
  licenses: any[];
  teams: any[];
}
const ShowCategoryFaq = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openِAddIcon, setOpenAddIcon] = React.useState(false);
  const handleOpenAddIcon = () => setOpenAddIcon(true);
  const handleCloseAddIcon = () => setOpenAddIcon(false);

  const [openDelete, setDelete] = React.useState(false);
  const handleOpenDelete = () => setDelete(true);
  const handleCloseDelete = () => setDelete(false);

  const [selected, setSelected] = React.useState<any>();

  const mapping: any = () => {
    return data?.data.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.title_ar}</td>
          <td className="content">{e?.title_en}</td>
          <td className="content">
            <img src={e?.media?.file_path}></img>
          </td>
          <td>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NavLink to={`/home/faqs/show/${e?.id}`}>
                <NoButton>
                  <RemoveRedEyeIcon />
                </NoButton>
              </NavLink>
              <NoButton onClick={() => addIcon(e)}>Add icon</NoButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  const select = (feed: any) => {
    setSelected(feed);
    handleOpenDelete();
  };
  const addIcon = (feed: any) => {
    setSelected(feed);
    handleOpenAddIcon();
  };
  // const { data, isLoading, isFetching } = useGetCompanyInfoQuery("");
  const [updateInfo, { data, isLoading, isFetching }] =
    useLazyGetAllCategoriesQuery();
  useEffect(() => {
    updateInfo("", true);
  }, [updateInfo]);
  console.log("Data", data?.data[0]);
  const companyInfo: CompanyInfo = data?.data[0];

  if (isLoading || isFetching) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <NoButton onClick={handleOpen}>Edit Info</NoButton>
      <Bigsection>
        <Grid container columns={12} spacing={3} mt={3}>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">Address</p>
              <p className="value">{companyInfo?.address}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">email</p>
              <p className="value">{companyInfo?.email}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">establishment year</p>
              <p className="value">{companyInfo?.establishment}</p>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">logo</p>
              <p className="value">
                <img src={companyInfo?.logo} alt="" />
              </p>
            </div>
          </Grid> */}
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">mobile</p>
              <p className="value">{companyInfo?.mobile}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">phone</p>
              <p className="value">{companyInfo?.phone}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">name</p>
              <p className="value">{companyInfo?.name}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="data">
              <p className="key">slogan</p>
              <p className="value">{companyInfo?.slogan}</p>
            </div>
          </Grid>
        </Grid>
      </Bigsection>

      <CreateCategoryFaq
        open={open}
        handleClose={handleClose}
        singleItem={selected}
        companyInfo={companyInfo}
        updateInfo={updateInfo}
      />
    </div>
  );
};

export default ShowCategoryFaq;
