import styled from "styled-components";

export const Bigsection = styled.section`
  overflow: auto;
  width: 100%;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    a {
      color: blue;
    }
    th,
    td {
      padding: 8px;
      padding: 8px;
      text-align: center;

      img {
        object-fit: contain;
        width: 150px;
        height: 140px;
        max-height: 100px; /* Ensure that images are not too tall */
      }
    }
    th,
    td {
      border: 1px solid black; /* Outline each cell */
      text-align: center; /* Center the text horizontally */
      vertical-align: middle; /* Center content vertically */
      padding: 10px; /* Add padding for better spacing */
    }
    .actions {
      display: flex;
      height: 100%;
      gap: 0.5rem;
      align-items: stretch;
      justify-content: center;
      width: 100%;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .content {
      max-width: 500px; /* Set the maximum width as needed */
      word-wrap: break-word; /* Allow words to break and wrap */
      white-space: normal; /* Allow content to wrap within the cell */
      max-height: 300px;
      overflow: auto;
    }
  }
  .createContainer {
    display: flex;
    justify-content: flex-end;
  }
  .create {
    padding: 0.5rem 1rem;
    cursor: pointer;
    /* background: linear-gradient(
      135deg,
      #4e5ae2 0%,
      #9e32a4 54.17%,
      #d552d8 100%
    ); */
    background-color: black;
    border: none;
    padding: 17px 16px;
    border-radius: 12px;
    color: #ffffff;
  }
  .data {
    display: flex;
    gap: 1rem;
    align-items: center;
    .key {
      font-size: 1rem;
      font-weight: bold;
      text-transform: capitalize;

      &:after {
        content: ":";
      }
    }
  }
`;
