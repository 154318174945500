import React from "react";
import {
  DeleteButton,
  NoButton,
} from "../../../../components/common/Buttons/deleteButton";
import { Bigsection } from "../../satisfiedCustomers/components/showData/style";
import { useGetAllCategoriesQuery } from "../../../../store/RTK/category";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link, NavLink } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import DeleteCategory from "../deleteCategoty";
import AddAssetBundle from "../addAssetBundle";
import UpdateCategory from "../updateCategory";
import UpdateCategoryImage from "../updateCategotyImage";
import UpdateCategoryPdf from "../updateCategoryPdf";
import AaddAssetBundleIOS from "../AaddAssetBundleIOS";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
const ShowCategoreis = () => {
  const { data, isLoading, isFetching } = useGetAllCategoriesQuery({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAssetBundle, setOpenAssetBundle] = React.useState(false);
  const handleOpenAsset = () => setOpenAssetBundle(true);
  const handleCloseAsset = () => setOpenAssetBundle(false);

  const [openAssetBundleIOS, setOpenAssetBundleIOS] = React.useState(false);
  const handleOpenAssetIOS = () => setOpenAssetBundleIOS(true);
  const handleCloseAssetIOS = () => setOpenAssetBundleIOS(false);

  const [openUpdateCategoryMedia, setOpenUpdateCategoryMedia] =
    React.useState(false);
  const handleOpenCategoryMedia = () => setOpenUpdateCategoryMedia(true);
  const handleCloseCategoryMedia = () => setOpenUpdateCategoryMedia(false);

  const [openUpdateCategoryPdf, setOpenUpdateCategoryPdf] =
    React.useState(false);
  const handleOpenCategoryPdf = () => setOpenUpdateCategoryPdf(true);
  const handleCloseCategoryPdf = () => setOpenUpdateCategoryPdf(false);

  const [openUpdateCategory, setOpenUpdateCategory] = React.useState(false);
  const handleOpenCAtegory = () => setOpenUpdateCategory(true);
  const handleCloseCAtegory = () => setOpenUpdateCategory(false);

  const [selected, setSelected] = React.useState<any>();
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };
  const selectAsset = (feed: any) => {
    setSelected(feed);
    handleOpenAsset();
  };
  const selectAssetIOS = (feed: any) => {
    setSelected(feed);
    handleOpenAssetIOS();
  };
  const selectUpdateCategory = (feed: any) => {
    setSelected(feed);
    handleOpenCAtegory();
  };
  const selectUpdateCategoryMedia = (feed: any) => {
    setSelected(feed);
    handleOpenCategoryMedia();
  };
  const selectUpdateCategoryPdf = (feed: any) => {
    setSelected(feed);
    handleOpenCategoryPdf();
  };
  const projects = data?.data[0]?.projects?.filter((e: any) => {
    return (
      e?.position === "project" &&
      (age === "commercial" || age === "residential"
        ? e?.project_type === age
        : e?.project_type)
    );
  });
  console.log("projects", projects);
  const mapping: any = () => {
    return projects.map((e: any) => {
      return (
        <tr key={e?.id}>
          <td className="content">{e?.address}</td>
          <td className="content">
            <a href={e?.image_path} target="_blank">
              <img src={e?.image_path} alt="" />
            </a>
          </td>
          <td className="content">{e?.project_type}</td>
          <td>{e?.project_location}</td>
          <td>{e?.project_space}</td>
          <td>{e?.project_style}</td>
          <td className="content">
            <div>{e?.description}</div>
          </td>

          <td>
            <div className="actions">
              <Link to={`/home/cetegory/singleCategory/${e?.id}`}>
                <Tooltip title="Show Single" placement="top">
                  <RemoveRedEyeIcon />
                </Tooltip>
              </Link>
              <NoButton onClick={() => selectUpdateCategory(e)}>
                Update
              </NoButton>
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
            </div>
          </td>
        </tr>
      );
    });
  };
  console.log("age", age);
  return (
    <Bigsection>
      <div className="createContainer">
        <NavLink to="/home/cetegory/create">
          <button className="create">Create Project</button>
        </NavLink>
      </div>
      {isFetching || isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "black", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black", // Focused border color
                },
              },
              "& .MuiInputLabel-root": {
                color: "black", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black", // Focused label color
              },
              "& .MuiInputLabel-root:hover": {
                color: "black", // Hover label color
              },
            }}
          >
            <InputLabel id="demo-simple-select-label" sx={{ color: "black" }}>
              Filter By Project type (Commercial / Residential)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Filter By Project type (Commercial / Residential)"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"commercial"}>Commercial</MenuItem>
              <MenuItem value={"residential"}>Residential</MenuItem>
            </Select>
          </FormControl>
          <table>
            <tr>
              <th>title</th>
              <th>Cover image</th>
              <th>project type</th>
              <th>project location</th>
              <th>project space</th>
              <th>project_style</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
            {projects?.length > 0 ? (
              <> {mapping()}</>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                No Data
              </div>
            )}
          </table>
        </>
      )}

      <DeleteCategory
        open={open}
        handleClose={handleClose}
        singleItem={selected}
      />
      <AddAssetBundle
        open={openAssetBundle}
        handleClose={handleCloseAsset}
        singleItem={selected}
      />
      <AaddAssetBundleIOS
        open={openAssetBundleIOS}
        handleClose={handleCloseAssetIOS}
        singleItem={selected}
      />
      <UpdateCategoryImage
        open={openUpdateCategoryMedia}
        handleClose={handleCloseCategoryMedia}
        singleItem={selected}
        key={selected}
      />
      <UpdateCategory
        open={openUpdateCategory}
        handleClose={handleCloseCAtegory}
        singleItem={selected}
        key={selected}
      />

      <UpdateCategoryPdf
        open={openUpdateCategoryPdf}
        handleClose={handleCloseCategoryPdf}
        singleItem={selected}
        key={selected}
      />
    </Bigsection>
  );
};

export default ShowCategoreis;
