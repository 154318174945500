import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";
import { companyID } from "../../config/Api";

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery,
  tagTypes: ["Faqs", "categoryUnity", "categoryUnityQuestion", "Answers"],
  endpoints: (builder) => ({
    getCompanyInfo: builder.query({
      query: () => ({
        url: `/companies/${companyID}`,
        method: "get",
      }),
      providesTags: ["Faqs"],
    }),

    updateFaqCategoryIcon: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `faq/upload-faq-category-icon/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Faqs"],
    }),
  }),
});

export const {
  useGetCompanyInfoQuery,

  useUpdateFaqCategoryIconMutation,
} = faqApi;
