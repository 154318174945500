import React from "react";
import { Bigsection } from "./style";
import { NavLink, Outlet } from "react-router-dom";

const HomeNav = () => {
  return (
    <Bigsection>
      {/* <ul>
        <NavLink to="account-type">
          <li>Account type</li>
        </NavLink>
        <NavLink to="satisfied-customer">
          {" "}
          <li>Satisfied customer</li>
        </NavLink>
      </ul> */}
      <div>
        <Outlet />
      </div>
    </Bigsection>
  );
};

export default HomeNav;
