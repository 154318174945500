import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../components/common/standerInput";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../components/common/Errors";
import { useCreateCategoryMutation } from "../../../../store/RTK/category";
import { Bigsection } from "./style";
import { companyID } from "../../../../config/Api";
import SelectInput from "../../../../components/common/SelectInput/SelectInput";
import StanderTextArea from "../../../../components/common/standardTextArea";
import { useNavigate } from "react-router-dom";

const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

const CreateCategory = () => {
  const schema = yup.object({
    address: yup.string().required("Title is required"),
    image_path: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true;
        return SUPPORTED_FORMATS.includes(value?.type);
      })
      .test("fileSize", "File size is too large", (value: any) => {
        if (!value) return true;
        return value.size <= MAX_FILE_SIZE;
      }),

    project_type: yup.string().required("Project Type type is required"),
    description: yup.string(),
    project_location: yup.string(),
    client_type: yup.string(),
    project_style: yup.string(),
    project_space: yup.string(),
    design_Style: yup.string(),
  });
  const naviage = useNavigate();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { control } = methods;
  const [error, setError] = React.useState([]);
  const [createCategory, { isSuccess, isLoading, isError }] =
    useCreateCategoryMutation();

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("company_id", companyID);
      formData.append("address", data.address);
      formData.append("image_path", data.image_path);
      formData.append("lng", "1565464");
      formData.append("lat", "4655466");
      formData.append("position", "project");
      formData.append("date", data?.date);
      formData.append("project_type", data?.project_type);
      formData.append("description", data.description);
      formData.append("client_type", data.client_type);
      formData.append("project_style", data.project_style);
      formData.append("project_space", data.project_space);
      formData.append("design_Style", data.design_Style);
      formData.append("project_location", data.project_location);

      await createCategory(formData).unwrap();
      toast.success("Project has been created");
      naviage("/home/cetegory");
      setError([]);
    } catch (error: any) {
      setError(error?.data?.errors || []);
      console.log("error", error?.status === 413);
      toast.error(
        `Error ${error?.status === 413 ? "file(s) size too large" : ""}`
      );
    }
  };

  const dropDown = [
    { id: "commercial", name: "commercial" },
    {
      id: "residential",
      name: "residential",
    },
    {},
  ];
  return (
    <Bigsection>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <h4>Add Project</h4>
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="address"
                placeholder="Title"
                Label="Title"
                type="string"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="date"
                placeholder="Project implementation year"
                Label="Date"
                type="date"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="">Image cover</label>
              <Controller
                control={methods.control}
                name="image_path"
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {methods.formState.errors?.image_path?.message}
              </p>
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="project_location"
                placeholder="Project location"
                Label="Project location"
                type="string"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="client_type"
                placeholder="client type"
                Label="client type"
                type="string"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="project_style"
                placeholder="Project style"
                Label="Project style"
                type="string"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="project_space"
                placeholder="Project space"
                Label="Project space"
                type="string"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="design_Style"
                placeholder="Design Style"
                Label="Design Style"
                type="string"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="project_type"
                placeholder="project_type"
                label="Project Type"
                defaultValue=""
                data={dropDown}
              />
            </Grid>
            <Grid item xs={12}>
              <StanderTextArea
                name="description"
                placeholder="Description"
                Label="Description"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth disabled={isLoading}>
                {isLoading ? "Creating..." : "Save"}
              </SubmitButton>
            </Grid>

            <Grid item xs={12}>
              <Errors errors={error} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Bigsection>
  );
};

export default CreateCategory;
