import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import { useCreateBlogMutation } from "../../../../../../store/RTK/blogs";
import { DeleteButton } from "../../../../../../components/common/Buttons/deleteButton";
import { SubmitButton } from "../../../../../../components/common/Buttons/SubmitButton";
import { Bigsection } from "../../../../satisfiedCustomers/components/showData/style";
import { useNavigate, useParams } from "react-router-dom";
import { useAddsubImagesMutation } from "../../../../../../store/RTK/category";

const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];
const CreateTarget = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.id;
  const schema = yup.object({
    image_path: yup.array().of(
      yup
        .mixed()
        .required("A file is required")
        .test(
          "fileType",
          "Unsupported file format",
          (value: any) => value && SUPPORTED_FORMATS.includes(value.type)
        )
    ),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      image_path: [{ undefined }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
      name: "image_path", // unique name for your Field Array+
    }
  );
  const [createBlog, { isLoading, isSuccess, isError }] =
    useAddsubImagesMutation();

  const onSubmit = async (data: any) => {
    console.log("data", data);

    const formData: any = new FormData();
    formData.append("project_id", id);

    data?.image_path?.map((e: any, i: number) => {
      formData.append(`image_path[${i}]`, e);
    });
    try {
      await createBlog(formData).unwrap();
      methods.reset();
      toast.success("Done");

      navigate(`/home/cetegory/singleCategory/${id}`);
    } catch (error: any) {
      console.log("error", error?.status === 413);
      toast.error(
        `Errrrrrr ${error?.status === 413 ? "file(s) size too large" : ""}`
      );
    }
  };

  const { formState }: any = methods;
  console.log("formState", formState?.errors?.image_path);
  let errs: any = formState?.errors;
  return (
    <Bigsection>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12} style={{}}>
              <h4>Add Images to Project</h4>
            </Grid>

            <Grid item xs={12}>
              <button
                type="button"
                onClick={() =>
                  append({
                    media: undefined,
                  })
                }
                className="create"
              >
                Add Image +
              </button>
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    gap: "1rem",
                    margin: "1rem 0",
                  }}
                >
                  <Controller
                    control={methods.control}
                    name={`image_path[${index}]`}
                    render={({ field: { value, onChange, ...field } }) => {
                      return (
                        <div>
                          <TextField
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                          <div>
                            <p className="err">
                              {formState?.errors?.image_path?.length > 0 &&
                                formState?.errors?.image_path[index]?.message &&
                                `${formState?.errors?.image_path[index]?.message}`}
                            </p>
                          </div>
                        </div>
                      );
                    }}
                  />

                  <DeleteButton type="button" onClick={() => remove(index)}>
                    Delete
                  </DeleteButton>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth disabled={isLoading}>
                {isLoading ? "creating..." : "Save"}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </Bigsection>
  );
};

export default CreateTarget;
