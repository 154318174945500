import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Grid, TextField } from "@mui/material";
import StanderInput from "../../../../../components/common/standerInput";
import StanderTextArea from "../../../../../components/common/standardTextArea";
import { SubmitButton } from "../../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../../components/common/Errors";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useLazyGetAllCategoriesQuery,
  useUpdateCompanyMutation,
} from "../../../../../store/RTK/category";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
interface CompanyInfo {
  id: number;
  name: string;
  slogan: string;
  address: string;
  phone: string;
  mobile: string;
  logo: string;
  establishment: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  email: string;
  title: string;
  button_key: string;
  button_value: string;
  socials: any[]; // Assuming an array of unknown objects, replace `any` with specific type if known
  sliders: any[];
  intros: any[];
  services: any[];
  facts: any[];
  values: any[];
  whies: any[];
  projects: any[];
  licenses: any[];
  teams: any[];
}
const CreateCategoryFaq = ({
  open,
  handleClose,
  companyInfo,
  updateInfo,
}:
  | { comanyInfo: CompanyInfo; open: boolean; handleClose: () => void }
  | any) => {
  const schema = yup.object({
    address: yup.string().required("address is required"),
    email: yup.string().email().required("email is required"),
    establishment: yup.string().required("establishment is required"),
    mobile: yup.string().required("mobile is required"),
    name: yup.string().required("name is required"),
    phone: yup.string().required("phone is required"),
    slogan: yup.string().required("slogan is required"),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  useEffect(() => {
    methods.setValue("address", companyInfo?.address);
    methods.setValue("email", companyInfo?.email);
    methods.setValue("establishment", companyInfo?.establishment);
    methods.setValue("mobile", companyInfo?.mobile);
    methods.setValue("name", companyInfo?.name);
    methods.setValue("phone", companyInfo?.phone);
    methods.setValue("slogan", companyInfo?.slogan);
  }, [companyInfo]);
  const [error, setError] = React.useState([]);

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      for (const property in data) {
        formData.append(property, data[property]);
      }
      console.log("data", data);
      await updateCompany(data).unwrap();
      await updateInfo("");
      handleClose();

      toast.success("Company updated successfully");
      methods.reset();
    } catch (error: any) {
      toast.error("something went wrong");
    }
  };
  const errs: any = methods?.formState?.errors;
  console.log("companyInfo", companyInfo);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12} style={{}}>
                <h4>Edit Company info</h4>
              </Grid>

              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="address"
                  placeholder="address"
                  Label="address"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="email"
                  placeholder="email"
                  Label="email"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="establishment"
                  placeholder="establishment"
                  Label="Establishment year"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="mobile"
                  placeholder="mobile"
                  Label="mobile"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="name"
                  placeholder="name"
                  Label="name"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="phone"
                  placeholder="phone"
                  Label="phone"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              <Grid item xs={12}>
                <StanderInput
                  // required
                  name="slogan"
                  placeholder="slogan"
                  Label="slogan"
                  type="string"
                  defaultValue=""
                ></StanderInput>
              </Grid>
              {/* <Grid item xs={12}>
                <label htmlFor="">Logo</label>
                <Controller
                  control={methods.control}
                  name={"logo"}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <TextField
                        {...field}
                        value={value?.fileName}
                        onChange={(event: any) => {
                          onChange(event.target.files[0]);
                        }}
                        type="file"
                        id="picture"
                      />
                    );
                  }}
                />
                <p className="err">{errs?.icon && errs?.icon?.message}</p>
              </Grid> */}

              <Grid item xs={12}>
                <SubmitButton type="submit" fullWidth>
                  {/* {isLoading ? "creating" : "Save"} */}
                  Edit
                </SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <Errors errors={error}></Errors>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default CreateCategoryFaq;
