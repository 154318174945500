import React from "react";
import { useDeleteCategoryMutation } from "../../../../store/RTK/category";
import { toast } from "react-toastify";
import { Bigsection } from "../../satisfiedCustomers/components/delete/style";
import { Box, Modal, Typography } from "@mui/material";
import { DeleteButton } from "../../../../components/common/Buttons/deleteButton";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};
const DeleteCategory = ({ open, handleClose, singleItem }: props) => {
  const [deleteCategory, { isLoading }] = useDeleteCategoryMutation();
  const id = singleItem?.id;

  const deleteFeed = async () => {
    try {
      await deleteCategory({ id }).unwrap();
      toast.success("Project has been deleted");
      handleClose();
    } catch (e) {
      toast.error("Error");
    }
  };

  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to delete this project
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <DeleteButton onClick={deleteFeed} disabled={isLoading}>
              {isLoading ? "Deleting..." : "Delete"}
            </DeleteButton>
            <SubmitButton className="btn" onClick={handleClose}>
              No
            </SubmitButton>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default DeleteCategory;
