import { Button } from "@mui/material";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
  && {
    background: var(--primary-color);
    border-radius: 6.01115px;
    color: white;
    padding: 10.0186px 15.0279px;
    transition: 0.3s all;
    &:hover {
      background: var(--primary-color);
      color: white;
      transform: translateY(-5px);
    }
    &:disabled {
      background: #b0bec5; /* Adjust color for disabled state */
      color: #f0f0f0; /* Change text color to match the disabled state */
      cursor: not-allowed; /* Change cursor to indicate it's disabled */
    }
  }
`;
