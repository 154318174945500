import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, TextField } from "@mui/material";
import StanderInput from "../../../../components/common/standerInput";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import Errors from "../../../../components/common/Errors";
import { Bigsection } from "./style";
import { companyID } from "../../../../config/Api";
import SelectInput from "../../../../components/common/SelectInput/SelectInput";
import StanderTextArea from "../../../../components/common/standardTextArea";
import { useCreateCategoryMutation } from "../../../../store/RTK/category";

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const CreateBlog = () => {
  const schema = yup.object({
    address: yup.string().required("Title is required"),
    image_path: yup
      .mixed()
      .required("A file is required")
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true;
        return SUPPORTED_FORMATS.includes(value?.type);
      }),

    // project_type: yup.string().required("Project Type type is required"),
    description: yup.string().required("Description type is required"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { control } = methods;
  const [error, setError] = React.useState([]);
  const [createCategory, { isSuccess, isLoading, isError }] =
    useCreateCategoryMutation();

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("company_id", companyID);
      formData.append("address", data.address);
      formData.append("image_path", data.image_path);
      formData.append("lng", "1565464");
      formData.append("lat", "4655466");
      formData.append("position", "kitchen");
      formData.append("date", data?.date);
      formData.append("project_type", "");
      formData.append("description", data.description);

      await createCategory(formData).unwrap();
      if (isSuccess) {
        toast.success("Category has been created");
        setError([]);
      }
    } catch (error: any) {
      setError(error?.data?.errors || []);
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Category has been created");
      methods.reset();
    }
    if (!isSuccess && isError) {
      toast.error("Something went wrong");
    }
  }, [isSuccess, isError]);
  const dropDown = [
    { id: "commercial ", name: "commercial " },
    {
      id: "residential",
      name: "residential",
    },
    {},
  ];
  return (
    <Bigsection>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3}>
            <Grid item xs={12}>
              <h4>Add Project Kitchen</h4>
            </Grid>

            <Grid item xs={12}>
              <StanderInput
                name="address"
                placeholder="Title"
                Label="Title"
                type="string"
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12}>
              <StanderInput
                name="date"
                placeholder="Date"
                Label="Date"
                type="date"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="">Image cover</label>
              <Controller
                control={methods.control}
                name="image_path"
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                    <TextField
                      {...field}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
              <p className="err">
                {methods.formState.errors?.image_path?.message}
              </p>
            </Grid>

            {/* <Grid item xs={12}>
              <SelectInput
                name="position"
                placeholder="Position"
                label="Project Type"
                defaultValue=""
                data={dropDown}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <SelectInput
                name="project_type"
                placeholder="project_type"
                label="Project Type"
                defaultValue=""
                data={dropDown}
              />
            </Grid> */}
            <Grid item xs={12}>
              <StanderTextArea
                name="description"
                placeholder="Description"
                Label="Description"
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <SubmitButton type="submit" fullWidth>
                {isLoading ? "Creating..." : "Save"}
              </SubmitButton>
            </Grid>

            <Grid item xs={12}>
              <Errors errors={error} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Bigsection>
  );
};

export default CreateBlog;
