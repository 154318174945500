import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import DeleteTarget from "./deleteTarget";
import UpdateTarget from "./UpdateTarget";
import { useGetBlogsQuery } from "../../../../../store/RTK/blogs";
import { useGetAllCategoriesQuery } from "../../../../../store/RTK/category";

const SigleCategory = () => {
  const params = useParams();
  const id = params?.id;
  const { data, isLoading } = useGetAllCategoriesQuery("");
  const targets = data?.data[0]?.projects;
  const singlePrjectImages =
    targets?.filter((e: any) => {
      return e?.id == id;
    }) || [];
  console.log("targets", targets);
  console.log("singlePrjectImages", singlePrjectImages[0]?.project_images);
  const [selected, setSelected] = React.useState<any>();
  const select = (e: any) => {
    setSelected(e);
  };
  const [open, setOpened] = React.useState<boolean>(false);
  const [openUpdate, setOpenedUpdate] = React.useState<boolean>(false);
  const handleClose = () => setOpened(false);
  const handleCloseUpdate = () => setOpenedUpdate(false);

  const tergetMapping: any = () => {
    return singlePrjectImages[0]?.project_images?.map((e: any, index: any) => {
      return (
        <tr key={e?.id}>
          <td>{++index}</td>
          <td>
            <a href={e?.image_path} target="_blank">
              <img src={e?.image_path} alt="" />
            </a>
          </td>
          <td>
            <div className="actions">
              <DeleteButton
                onClick={async () => {
                  select(e);
                  setOpened(!open);
                }}
              >
                Delete
              </DeleteButton>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Bigsection>
        <nav>
          <ul>
            <NavLink to="add-target">
              <li>
                <button className="create">Add sub project images</button>
              </li>
            </NavLink>
          </ul>
        </nav>
        <div>
          <h1>Sub project images</h1>
          <table>
            <tr>
              <td>Index</td>
              {/* <td>Is free</td> */}
              <td>Images</td>
              <td>Actions</td>
            </tr>
            {targets?.length > 0 && tergetMapping()}
          </table>
        </div>
      </Bigsection>
      <DeleteTarget
        open={open}
        singleItem={selected}
        key={selected?.id}
        handleClose={handleClose}
      />
      <UpdateTarget
        open={openUpdate}
        singleItem={selected}
        key={selected?.id}
        handleClose={handleCloseUpdate}
      />
    </>
  );
};

export default SigleCategory;
