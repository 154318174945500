import React from "react";
import {
  useAddAssetBundleMutation,
  useUpdateCategoryMutation,
  // useUpdateCategoryMutation,
} from "../../../../store/RTK/category";
import { Bigsection } from "../../satisfiedCustomers/components/delete/style";
import {
  Box,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import StanderInput from "../../../../components/common/standerInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import StanderTextArea from "../../../../components/common/standardTextArea";
import { companyID } from "../../../../config/Api";
import SelectInput from "../../../../components/common/SelectInput/SelectInput";
import Errors from "../../../../components/common/Errors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "100%",
  overflow: "scroll",
  height: "70%",
  display: "block",
};

type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const UpdateCategory = ({ open, handleClose, singleItem }: props) => {
  const [single, setSingle] = React.useState<any>();
  console.log("single", single);

  const schema = yup.object({
    address: yup.string().required("Title is required"),
    image_path: yup
      .mixed()
      .nullable() // Allows null values
      .test("fileType", "Unsupported file format", (value: any) => {
        if (!value) return true; // Bypass validation if no file is provided
        return SUPPORTED_FORMATS.includes(value?.type);
      }),
    project_type: yup.string().required("Project Type type is required"),
    description: yup.string().required("Description type is required"),
    project_location: yup.string(),
    client_type: yup.string(),
    project_style: yup.string(),
    project_space: yup.string(),
    design_Style: yup.string(),
  });
  const id = singleItem?.id;
  const methods: any = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      is_publish: +true,
    },
  });
  const control = methods.control;
  const [update, { isLoading, error }] = useUpdateCategoryMutation();
  console.log("methods", methods.formState.errors);
  const onSubmit = async (data: any) => {
    try {
      console.log("Sned data", data);
      const formData = new FormData();
      formData.append("company_id", companyID);
      formData.append("_method", "PUT");
      formData.append("address", data.address);
      formData.append("image_path", data.image_path);
      formData.append("lng", "1565464");
      formData.append("lat", "4655466");
      formData.append("position", "project");
      formData.append("date", data?.date);
      formData.append("project_type", data?.project_type);
      formData.append("description", data.description);
      formData.append("client_type", data.client_type);
      formData.append("project_style", data.project_style);
      formData.append("project_space", data.project_space);
      formData.append("design_style", data.design_Style);
      formData.append("project_location", data.project_location);

      const sendData = {
        id: singleItem?.id,
        body: formData,
      };

      await update(sendData).unwrap();
      toast.success("Edited");
      // handleClose();
    } catch (error: any) {
      toast.error("Error");
    }
  };
  // React.useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Category has been added");
  //     handleClose();
  //   }
  //   if (!isSuccess && isError) {
  //     toast.error("somthing wrong");
  //   }
  // }, [isSuccess, isError]);
  React.useLayoutEffect(() => {
    methods.setValue("address", singleItem?.address);
    methods.setValue("lng", "1565464");
    methods.setValue("lat", "4655466");
    methods.setValue("position", "project");
    methods.setValue("date", singleItem?.date);
    methods.setValue("project_type", singleItem?.project_type);
    methods.setValue("description", singleItem?.description);
    methods.setValue("client_type", singleItem?.client_type);
    methods.setValue("project_style", singleItem?.project_style);
    methods.setValue("project_space", singleItem?.project_space);
    methods.setValue("design_Style", singleItem?.design_style);
    methods.setValue("project_location", singleItem?.project_location);
  }, [singleItem]);
  const dropDown = [
    { id: "commercial", name: "commercial" },
    {
      id: "residential",
      name: "residential",
    },
    {},
  ];
  console.log("watch", methods.watch());
  console.log("singleItem", singleItem);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Update
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12}>
                    <StanderInput
                      name="address"
                      placeholder="Title"
                      Label="Title"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      name="date"
                      placeholder="Date"
                      Label="Date"
                      type="date"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <label htmlFor="">Image cover</label>
                    <Controller
                      control={methods.control}
                      name="image_path"
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <TextField
                            {...field}
                            onChange={(event: any) => {
                              onChange(event.target.files[0]);
                            }}
                            type="file"
                            id="picture"
                          />
                        );
                      }}
                    />
                    <p className="err">
                      {methods.formState.errors?.image_path?.message}
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectInput
                      name="project_type"
                      placeholder="project_type"
                      label="Project Type"
                      defaultValue=""
                      data={dropDown}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      name="project_location"
                      placeholder="Project location"
                      Label="Project location"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StanderInput
                      name="client_type"
                      placeholder="client type"
                      Label="client type"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StanderInput
                      name="project_style"
                      placeholder="Project style"
                      Label="Project style"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StanderInput
                      name="project_space"
                      placeholder="Project space"
                      Label="Project space"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StanderInput
                      name="design_Style"
                      placeholder="Design Style"
                      Label="Design Style"
                      type="string"
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      name="project_type"
                      placeholder="project_type"
                      label="Project Type"
                      defaultValue=""
                      data={dropDown}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StanderTextArea
                      name="description"
                      placeholder="Description"
                      Label="Description"
                      defaultValue=""
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth disabled={isLoading}>
                      {isLoading ? "Creating..." : "Save"}
                    </SubmitButton>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Errors errors={error} />
                  </Grid> */}
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default React.memo(UpdateCategory);
