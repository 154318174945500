import { FormContainerStyle, HeaderLogin, LoginImage } from "./loginPageStyle";
import { Grid } from "@mui/material";
import loginImage from "../../assets/login/furniture_darkgreen_1_4_-min.jpg";
import LoginForm from "../../components/Login/LoginForm";
import { useState } from "react";

const LoginPage = () => {
  return (
    <HeaderLogin>
      <Grid container spacing={10}>
        <Grid item md={6} xs={12}>
          <FormContainerStyle>
            <LoginForm />
          </FormContainerStyle>
        </Grid>
        <Grid item md={6} xs={12} sx={{ display: { md: "flex", xs: "none" } }}>
          <LoginImage>
            <img src={loginImage} alt="login" />
          </LoginImage>
        </Grid>
      </Grid>
    </HeaderLogin>
  );
};

export default LoginPage;
