import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";
import { companyID } from "../../config/Api";

export const categoryeApi = createApi({
  reducerPath: "categoryeApi",
  baseQuery,
  tagTypes: ["Category", "Target"],
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => ({
        url: `/companies/${companyID}`,
        method: "get",
      }),
      providesTags: ["Category"],
    }),
    createCategory: builder.mutation<any, any>({
      query: (body) => ({
        url: "/projects",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Category"],
    }),
    updateCompany: builder.mutation<any, any>({
      query: (body) => ({
        url: `/companies/${companyID}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategory: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/projects/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategoryMedia: builder.mutation<any, any>({
      query: ({ data, id }) => ({
        url: `/categories/update-category-cover/${id}`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategoryPdf: builder.mutation<any, any>({
      query: ({ data, id }) => ({
        url: `/categories/update-category-pdf/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Category"],
    }),
    deleteCategory: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
    addTarget: builder.mutation<any, any>({
      query: (body) => ({
        url: "/categories/add-target",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Target"],
    }),
    addAssetBundle: builder.mutation<any, any>({
      query: (body) => ({
        url: "/categories/add-asset-bundle",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Target"],
    }),
    addAssetBundleIOS: builder.mutation<any, any>({
      query: (body) => ({
        url: "/categories/add-asset-bundle-ios",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Target"],
    }),
    deleteTarget: builder.mutation<any, any>({
      query: (id) => ({
        url: `image-projects/${id}`,
        method: "Delete",
      }),
      invalidatesTags: ["Target", "Category"],
    }),
    addsubImages: builder.mutation<any, any>({
      query: (body) => ({
        url: "/image-projects",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Target", "Category"],
    }),
    updateTarget: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `categories/update-target/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Target"],
    }),
    getSingleCategories: builder.query({
      query: ({ id }) => ({
        url: `/categories/show/${id}`,
        method: "get",
      }),
      providesTags: ["Target"],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetSingleCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCompanyMutation,
  useUpdateCategoryMediaMutation,
  useUpdateCategoryPdfMutation,
  useDeleteCategoryMutation,
  useAddTargetMutation,
  useAddAssetBundleMutation,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
  useAddAssetBundleIOSMutation,
  useLazyGetAllCategoriesQuery,
  useUpdateCategoryMutation,
  useAddsubImagesMutation,
} = categoryeApi;
