import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";
import { companyID } from "../../config/Api";

export const furnitureApi = createApi({
  reducerPath: "furnitureApi",
  baseQuery,
  tagTypes: ["furn"],
  endpoints: (builder) => ({
    getFurn: builder.query({
      query: () => ({
        url: `media/${companyID}`,
        method: "get",
      }),
      providesTags: ["furn"],
    }),
    storeFurn: builder.mutation<any, any>({
      query: (body) => ({
        url: `media`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["furn"],
    }),

    deleteFurn: builder.mutation<any, any>({
      query: (id) => ({
        url: `media/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["furn"],
    }),
  }),
});

export const {
  useLazyGetFurnQuery,
  useGetFurnQuery,
  useStoreFurnMutation,
  useDeleteFurnMutation,
} = furnitureApi;
